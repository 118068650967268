<template>
	<div class="publicPacAdd">
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/public'>公告管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{(edit?'编辑':'新增')+'公告'}}</el-breadcrumb-item>
    </el-breadcrumb>-->
		<el-form :rules="dataRules" label-width="140px" class="form-con-left5" :model="form" ref="form"
			v-loading="dataListLoading">


			<el-form-item label="型号名称" >
				{{form.typeName}}
			</el-form-item>
			<el-form-item label="型号编号">
				{{form.typeNumber}}
				</el-form-item>
			<el-form-item label="类型说明">
				{{form.typeName}}</el-form-item>
			<el-form-item label="主屏地址" >
				{{form.urlFirst}}</el-form-item>
			<el-form-item label="副屏地址" >
				{{form.urlSecond}}</el-form-item>



			

		</el-form>


	</div>

</template>
<script>
	import Qs from "qs";
	import moment from "moment";
	export default {
		name: "publicAdd",
		components: {


		},
		data() {
			return {
				key: 0,
				visible: false,
				edit: false,
				qsDialog: true,
				actionUrl: '',
				importHeader: {
					token: ""
				},
				dataList: [],
				list: [],
				contentType: 1,
				contentTypeArr: ['AI测评模型', '表单问卷'],

				deviceType: '',
				col: [
					// {
					// 	label: '序号',
					// 	prop: 'orderNum'
					// },
					{
						label: '类型',
						prop: 'contentTypeName'
					}, {
						label: '名称',
						prop: 'relationName'
					},
					{
						label: '操作',
						prop: 'isClick'
					}
				],
				fileList: [],
				fileList2: [],
				checkList: [],
				form: {


					typeName: "",
					typeNote: "",
					urlFirst: "",
					typeNumber: "",
					urlSecond: "",
				},
				diseasesList: [],
				typeList: [],
				dataRules: {
					typeName: [{
						required: true,
						message: "型号名称不能为空",
						trigger: "blur"
					}, ],
					urlFirst: [{
						required: true,
						message: "主屏地址不能为空",
						trigger: "blur"
					}, ],
					urlSecond: [{
						required: true,
						message: "副屏地址不能为空",
						trigger: "blur"
					}, ],
					typeNumber: [{
						required: true,
						message: "型号编号不能为空",
						trigger: "blur"
					}, ]

				},
				dataListLoading: false,
				type: "",
			};
		},
		async mounted() {

			let query = this.$route.query;
			if (query.id) {
				this.edit = true;
			
				await this.init(query.id);
			}
			await this.getTypeList();

		},
		methods: {
			indexMethod(index) {
				return index + 1;
			},
			async submit() {



				this.$refs.form.validate(async (valid) => {
					if (valid) {
						const {
							data: res
						} = await this.$httpAes({
							url: this.$httpAes.adornUrl(
								this.form.id ? "/deviceType/update" :
								"/deviceType/add "
							),
							method: "post",
							contentType: 'json',
							headers: {
								'Content-Type': 'application/json',

							},
							data: this.form,
						});
						console.log(res);
						if (res.status) {
							this.$message.success(res.msg);
							this.$router.go(-1)

						}
					} else {
						return false;
					}
				});
			},
			async submit2(id, flag) {
				if (this.list.length == 0) {
					this.$message.error('请选择套餐内容')
					return
				}
				for (let item of this.list) {
					item.packageId = id
				}
				let obj = {
					stringParam1: this.list
				}


				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/packageContent/add "
					),
					method: "post",
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
					data: obj,
				});
				console.log(res);
				if (res.status) {
					this.$message.success(res.msg);
					if (flag) {
						this.$router.push({
							name: "evaluationPackageSetting",
							query: {
								id: id
							},
						});
					} else {
						this.$router.go(-1)
					}


				}
			},
			submitNext() {
				if (this.list.length == 0) {
					this.$message.error('请选择套餐内容')
					return
				}
				this.$refs.form.validate(async (valid) => {
					if (valid) {



						const {
							data: res
						} = await this.$httpAes({
							url: this.$httpAes.adornUrl(
								this.form.id ? "/evaluationPackage/update" :
								"/evaluationPackage/add "
							),
							method: "post",
							contentType: 'json',
							headers: {
								'Content-Type': 'application/json',

							},
							data: this.form,
						});
						console.log(res);
						if (res.status) {
							this.submit2(res.data.id, true)


						}
					} else {
						return false;
					}
				});
			},
			delTb(row, index) {
				this.list.splice(index, 1)
			},
			rowDrop() {
				const tbody = document.querySelector('.el-table__body-wrapper tbody')

			},
			// 		rowDrop() {
			// 		const tbody = document.querySelector('.el-table__body-wrapper tbody')
			// 		const _this = this
			// 		Sortable.create(tbody, {
			// 		onEnd({ newIndex, oldIndex }) {
			// 				console.log( newIndex, oldIndex )
			// 		const currRow = _this.list.splice(oldIndex, 1)[0]
			// 		_this.list.splice(newIndex, 0, currRow)
			// 		console.log( _this.list)

			// 		 for (let index in  _this.list) {
			// 			 _this.list[index].orderNum=''
			// 		 	 _this.list[index].orderNum = parseInt(index) +1
			// 		 }
			// 		_this.$nextTick(()=>{

			// 		  _this.key++;

			// 		})

			// 		},
			// 		})
			// 		},
			getOther(arr) {
				console.log(arr, "val gteOther");

				var that = this
				arr.forEach((item) => {
					let obj = {
						orderNum: that.list.length + 1,
						contentType: that.contentType,
						contentTypeName: that.contentTypeArr[that.contentType - 1],
						relationName: item.tableName,
						relationId: item.id,
						isClick: '',
					}
					let flag = false
					for (let obj of that.list) {
						if (obj.relationId == item.id) {

							flag = true
						}
					}
					if (flag) {
						this.$message.warning('内容选择重复')

					} else {
						that.list.push(obj)
						that.relationObj = ''
						that.visible = false
					}

				})
			},
			addPac() {
				if (this.contentType == 1) {
					if (this.relationObj != '') {
						let obj = {
							orderNum: this.list.length + 1,
							contentType: this.contentType,
							contentTypeName: this.contentTypeArr[this.contentType - 1],
							relationName: this.relationObj.modelName,
							relationId: this.relationObj.id,
							isClick: '',
						}
						for (let item of this.list) {
							if (item.relationId == this.relationObj.id) {
								this.$message.warning('内容选择重复')
								return
							}

						}
						this.list.push(obj)
						this.relationObj = ''
						this.visible = false
						console.log(this.list)
					} else {
						this.$message.warning('请选择内容名称')
						return
					}
				} else {

				}

			},

			setCheck(val) {



			},

			handleSuccess2(response, file, fileList) {
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList2 = [obj]
			},
			handleSuccess(response, file, fileList) {
				console.log(response, "response");
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList = [obj]

			},
			showChoose() {
				console.log('showChooseshowChoose');
				this.qsDialog = true;
				sessionStorage.setItem("type", 1);


				this.$nextTick(() => {

					this.$refs.chooseQs.init();
					// if (this.form.followUpItem.length) {
					//   this.$refs.chooseQs.edit(this.form.followUpItem);
					// }
				});
			},
			beforeUpload(file, fileType) {
				let fileName = file.name;
				let pos = fileName.lastIndexOf(".");
				let lastName = fileName.substring(pos, fileName.length);
				let type = lastName.toLowerCase();
				if (fileType == "1") {

					if (
						type != ".mp4"

					) {
						this.$message.error(
							"上传文件只能是.mp4 格式!"
						);
						this.fileList = [];
						return false;
					}
					return true;
				} else if (fileType == "2") {
					// let isLt2M = file.size / 1024 / 1024 < 50;
					if (type != ".txt") {
						this.$message.error("上传文件只能是.txt格式!");
						this.fileList2 = [];
						return false;
					}

					return true;
				}
			},
			back() {
				window.close()
			},
			beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 10;
				if (!isLt2M) {
					this.$message.error("图片大小不能超过10MB");
				}
			},
			handleUploadSuccess(response, file, fileList) {
				console.log(file);
				this.form.picUrl = file.response.result.filePath;
			},
			async init(id) {
		 this.form  	 = JSON.parse(localStorage.deviceTypeInfo)
		 console.log(this.form)
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				

			},
			async getTypeList() {
				let params = {
					pageSize: 9999,
					pageNo: 1,
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("deviceType/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				console.log(res, '设备型号')
				this.typeList = res.data;
			},


			async deleteHousePic(id) {
				let params = {
					stringParam1: id,
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/deleteHousePic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});

			},
			async getList() {

				let params = {


					pageSize: 9999,
					pageNo: 1,
				};

				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/diagnosisModel/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					this.dataList = res.data;
					console.log('this.dataList')
					console.log(this.dataList)
				}
			},

		},
	};
</script>

<style lang="scss">
	::v-deep .el-dialog .el-dialog__body {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.publicPacAdd {

		// .el-form-item__content{
		// 	margin-left: 0 !important;
		// }
		.tianjia {
			cursor: pointer;
			margin-top: 20px;
			width: 68px;
			height: 28px;
			line-height: 28px;
			text-align: center;
			border-radius: 2px;
			border: 1px solid #A767FD;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #A767FD;
		}
	}
</style>